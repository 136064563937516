// Imports

import React from "react"

import SEO from "../components/seo"
import Layout from "../components/layout"
import { FormsHook } from '../hooks/forms'
import formsPageStles from "../styles/pages/forms.module.css"
import MedicalForms from "../components/medical-forms/medical-forms"

// Forms

const FormsPage = ({ data }) => {
  const forms = FormsHook()
  return (
    <Layout>
      <SEO title="ACE | Nevada's Leading Cannabis Testing Lab" />
      <div className={formsPageStles.hero}>
        <div className={formsPageStles.callToAction}>
          <h3 className={formsPageStles.title}>Articles & Publications</h3>
        </div>
      </div>
    </Layout>
  )
}

export default FormsPage
